import anime from "animejs";
import { Location } from "swup";
import swup from "../swup.js";

export default (categoriesWithSubNavs) => {
    return {
        categoriesWithSubNavs,

        isOpen: false,
        initialised: false,
        showItems: false,
        numberOfItems: null,
        isClosingFromOutside: true,

        currentlyOpenSubNav: null,

        subNavHeight: 40, // may increase if subnav breaks to multiple lines
        gap: 4,

        get hasMultipleSubNavs() {
            return this.categoriesWithSubNavs.length > 1;
        },

        get isOnHome() {
            return this.$store.brandMenu.isOnHome;
        },

        get selectedItem() {
            return this.$store.brandMenu.selectedItem;
        },

        get selectedChild() {
            return this.$store.brandMenu.selectedChild;
        },

        get hasChildren() {
            return this.categoriesWithSubNavs.includes(this.selectedItem);
        },

        get show() {
            return this.isMobile && this.$store.brandMenu.showBrandMenu;
        },

        get isMobile() {
            return !this.$store.breakpoint.showDesktopNav;
        },

        get position() {
            return this.$store.breakpoint.sm ? "top" : "bottom";
        },

        get pillHeight() {
            return this.$store.breakpoint.sm ? 52 : 40;
        },

        get directionMultiplier() {
            return this.$store.breakpoint.sm ? -1 : 1;
        },

        init() {
            const items = this.$el.querySelectorAll("[data-item]");
            this.numberOfItems = items.length;

            // Remove no js fallback classes
            for (const el of items) {
                el.classList = "";
            }

            this.$watch("show", (newValue) => {
                this.onResize();
                if (newValue) {
                    this.selectedItemChanged(this.selectedItem, null);
                }
            });

            this.$watch("position", () => this.onResize());

            this.$nextTick(() => {
                if (this.show) {
                    this.initialise();
                }
            });
        },

        initialise() {
            if (this.initialised) {
                return;
            }

            // set initial open subnav
            this.currentlyOpenSubNav = this.hasChildren
                ? this.selectedItem
                : null;

            this.setPositionAndSizes();

            // Hide all subNavs
            anime.set(
                "#brandMenuMobile [data-subnav]:not([data-selected-subnav]) > li",
                {
                    translateY: this.pillHeight * this.directionMultiplier,
                    opacity: 0,
                },
            );

            if (this.hasChildren) {
                setTimeout(() => {
                    this.setCurrentSubNavHeight();
                    this.setPositionOfSelectedItem(0);
                    this.initialised = true;
                }, 100);
            } else {
                this.$nextTick(() => {
                    this.initialised = true;
                });
            }

            this.$watch("subNavHeight", async () => {
                !this.isOpen ? this.setPositionOfSelectedItem() : null;
            });

            this.$watch("$store.brandMenu.selectedItem", (newValue, oldValue) =>
                this.selectedItemChanged(newValue, oldValue),
            );
        },

        async onResize() {
            if (!this.show) {
                return;
            }

            if (!this.initialised) {
                this.initialise();
                return;
            }

            await this.$nextTick();

            this.setPositionAndSizes();
            this.setPositionOfSelectedItem();
            this.close();
        },

        setPositionAndSizes() {
            this.setCurrentSubNavHeight();

            if (this.hasMultipleSubNavs) {
                this.$nextTick(() => {
                    this.updateSubNavOffset();
                });
            }
        },

        setPositionOfSelectedItem(duration = 500) {
            // Set offset in case item has a subnav
            anime({
                targets: "#brandMenuMobile [data-selected]",
                translateY: -this.subNavHeight * this.directionMultiplier,
                duration,
                easing: "easeOutElastic(1, 0.7)",
            });
        },

        setCurrentSubNavHeight() {
            this.subNavHeight = this.hasChildren
                ? (this.$refs["subNav" + this.selectedItem].clientHeight || 0) +
                  this.gap
                : 0;
        },

        updateSubNavOffset() {
            let subHeight = 0;
            for (const el of this.$el.querySelectorAll(
                "[data-subnav-offset]",
            )) {
                anime.set(el, {
                    translateY: subHeight * this.directionMultiplier,
                });

                subHeight += el.offsetHeight + this.gap;
            }
        },

        itemIsSelected(slug) {
            return this.selectedItem === slug;
        },

        applySelectedClass(slug) {
            return slug !== "__home__"
                ? this.itemIsSelected(slug)
                : this.isOnHome;
        },

        childTabIndex(slug) {
            const isSelected = this.selectedItem === slug;

            if (this.isOpen && isSelected) {
                // todo: avoid tabindex > 0
                return "1";
            }

            return isSelected ? "" : "-1";
        },

        linkClicked(event) {
            const { url } = Location.fromElement(event.currentTarget);

            const navigateToHome = this.$store.brandMenu.uriIsHome(url);

            if (
                (navigateToHome && swup.getCurrentUrl() === url) ||
                (!navigateToHome && swup.getCurrentUrl().startsWith(url))
            ) {
                this.toggle();
            } else {
                this.navigateToUrl(url);
            }
        },

        navigateToUrl(url) {
            this.isClosingFromOutside = false;
            swup.navigate(url);
            setTimeout(() => {
                this.isClosingFromOutside = true;
            }, 500);
        },

        childLinkClicked(event) {
            const { url } = Location.fromElement(event.currentTarget);

            if (url === swup.getCurrentUrl()) {
                const urlParts = url.split("/");
                urlParts.pop();
                const parentUrl = urlParts.join("/");
                this.navigateToUrl(parentUrl);
            } else {
                this.navigateToUrl(url);
            }

            this.close();
        },

        toggle() {
            this.isOpen ? this.close() : this.open();
        },

        async open() {
            if (this.isOpen) {
                return;
            }

            this.isOpen = true;
            this.showItems = true;

            await this.$nextTick();

            const subNavHeight =
                -this.directionMultiplier *
                (this.subNavHeight + this.pillHeight);

            const staggeredPositions =
                -this.directionMultiplier *
                (this.subNavHeight +
                    this.pillHeight * (this.numberOfItems - 1));

            anime({
                targets: "#brandMenuMobile [data-item]:not([data-selected])",
                translateY: anime.stagger([subNavHeight, staggeredPositions]),
                opacity: 1,
                delay: anime.stagger(20),
            });
        },

        close() {
            if (!this.isOpen) {
                return;
            }

            this.isOpen = false;

            anime({
                targets: "#brandMenuMobile [data-item]:not([data-selected])",
                translateY: this.pillHeight * this.directionMultiplier,
                opacity: 0,
                delay: this.isClosingFromOutside
                    ? 0
                    : anime.stagger(50, { easing: "easeOutQuad" }),
                easing: "easeInQuint",
                duration: 400,
                complete: () => {
                    this.showItems = false;
                },
            });
        },

        resetSelectedItemPosition() {
            if (this.isClosingFromOutside) {
                anime.set("#brandMenuMobile [data-selected]", {
                    translateY: this.directionMultiplier * this.pillHeight,
                    opacity: 0,
                });
            }
        },

        animateSelectedItem() {
            // console.log('animateSelectedItem', this.isClosingFromOutside);

            anime({
                targets: "#brandMenuMobile [data-selected]",
                translateY: this.hasChildren
                    ? -this.directionMultiplier * this.subNavHeight
                    : 0,
                delay: this.isClosingFromOutside ? 600 : 200,
                opacity: 1,
                duration:
                    !this.hasChildren && this.position === "top" ? 700 : 1000,
                // easing: 'easeOutSine',
                easing:
                    !this.hasChildren && this.position === "top"
                        ? "easeOutElastic(1, .8)"
                        : "easeOutElastic(1, .5)",
            });
        },

        closeSubNav() {
            if (
                (!this.hasChildren && this.currentlyOpenSubNav) ||
                (this.hasChildren &&
                    this.currentlyOpenSubNav &&
                    this.currentlyOpenSubNav !== this.selectedItem)
            ) {
                const itemsToClose =
                    this.$refs["subNav" + this.currentlyOpenSubNav].children ||
                    null;

                if (itemsToClose && this.currentlyOpenSubNav) {
                    // this.showSubItems = true;

                    anime({
                        targets: itemsToClose,
                        opacity: 0,
                        duration: 400,
                        easing: "easeOutElastic(1, .5)",
                        translateY: this.pillHeight * this.directionMultiplier,
                        delay:
                            itemsToClose.length > 1
                                ? anime.stagger(50, {
                                      start: 200,
                                      easing: "easeOutQuad",
                                  })
                                : 200,
                        // complete: () => {
                        //     this.showSubItems = false;
                        // },
                    });
                }
            }
        },

        openSubNav() {
            if (
                this.hasChildren &&
                this.currentlyOpenSubNav !== this.selectedItem
            ) {
                const itemsToOpen =
                    this.$refs["subNav" + this.selectedItem].children || null;

                this.currentlyOpenSubNav = this.selectedItem;

                if (!itemsToOpen) {
                    return;
                }

                anime({
                    targets: itemsToOpen,
                    delay:
                        itemsToOpen.length > 1
                            ? anime.stagger(50, { start: 300 })
                            : 300,
                    duration: 1000,
                    easing: "easeOutElastic(1, .5)",
                    translateX: (el) => [-el.offsetLeft, 0],
                    translateY: [
                        this.isClosingFromOutside
                            ? this.directionMultiplier * this.pillHeight
                            : -this.directionMultiplier * this.pillHeight,
                        0,
                    ],
                    opacity: 1,
                });
            }
        },

        updateSubNav() {
            this.closeSubNav();
            if (!this.hasChildren) {
                this.currentlyOpenSubNav = null;
            }
            this.openSubNav();
        },

        async selectedItemChanged(newValue, oldValue) {
            if (!this.show) {
                return;
            }

            if (newValue !== oldValue) {
                this.resetSelectedItemPosition();
                await this.$nextTick();
                this.setCurrentSubNavHeight();
                this.animateSelectedItem();
                this.updateSubNav();
            }

            await this.$nextTick();
            this.close();
        },
    };
};
